import React from "react"

const fieldsUrlsNames = (wantedArrayItem, type = "district") => {
    // full paths:
    // /oblasti/zoznam-oblasti-podla-poctu-obyvatelov
    // /kraje/zoznam-krajov-podla-nazvu
    // /okresy/zoznam-okresov-podla-nazvu
    // /regiony-cestovneho-ruchu/zoznam-regionov-podla-poctu-obyvatelov
    // /mesta/zoznam-miest-podla-podielu-muzov
    // /dediny/zoznam-dedin-podla-podielu-muzov
    // /obce/zoznam-obci-podla-podielu-muzov


    // const startPath = '/okresy/zoznam-okresov-'
    

    const array = [
        ['field_population_total', 'Podľa počtu obyvateľov', 'podla-poctu-obyvatelov'],
        ['field_size_total', 'Podľa rozlohy', 'podla-rozlohy'],
        ['field_population_density_total', 'Podľa hustoty obyvateľov', 'podla-hustoty-obyvatelov-na-km2'],
        ['field_population_share_male', 'Podľa podielu mužov', 'podla-podielu-muzov'],
        ['field_population_share_female', 'Podľa podielu žien', 'podla-podielu-zien'],
        ['field_mention', 'Podľa prvej zmienky', 'podla-prvej-zmienky'],
        ['field_asl', 'Podľa nadmorskej výšky', 'podla-nadmorskej-vysky'],
        ['field_num_of_municipalities', 'Podľa počtu obcí', 'podla-poctu-obci'],
        ['field_num_of_cities', 'Podľa počtu miest', 'podla-poctu-miest'],
        ['field_num_of_villages', 'Podľa počtu dedín', 'podla-poctu-dedin'],
        ['field_num_of_districts', 'Podľa počtu okresov', 'podla-poctu-okresov'],
        ['field_num_of_counties', 'Podľa počtu krajov', 'podla-poctu-krajov'],
        ['field_neighbours_num', 'Podľa počtu susedov', ''],
        // podla-poctu-susednych-obci
        // podla-poctu-susednych-okresov
        // podla-poctu-susednych-krajov
        // podla-poctu-susednych-oblasti
        // podla-poctu-susednych-regionov
        //zoznam XXX podla ... /zoznam-krajov-podla-hustoty-obyvatelstva-v-mestach
        ['field_size_only_cities_p', 'Podľa podielu miest na rozlohe', 'podla-podielu-miest-na-rozlohe'],
        ['field_size_only_villages_p', 'Podľa podielu dedín (vidieka) na rozlohe', 'podla-podielu-vidieka-na-rozlohe'],
        ['field_population_only_cities_p', 'Podľa podielu miest na počte obyvateľov', 'podla-podielu-obyvatelstva-v-mestach'],
        ['field_pop_only_villages_p', 'Podľa podielu dedín na počte obyvateľov', 'podla-podielu-obyvatelstva-na-vidieku'],
        ['field_p_density_cities', 'Podľa podielu miest na hustote obyvateľov', 'podla-hustoty-obyvatelstva-v-mestach'],
        ['field_p_density_villages', 'Podľa podielu dedín na hustote obyvateľov', 'podla-hustoty-obyvatelstva-na-vidieku'],
        ['field_population_change_1y_share', 'Podľa podielu zmeny počtu obyvateľov za posledný rok', 'podla-prirastku-obyvatelov-za-minuly-rok'],
        ['field_population_change_5y_share', 'Podľa podielu zmeny počtu obyvateľov za 5 rokov', 'podla-prirastku-obyvatelov-za-5-rokov'],
        ['field_population_change_alltime_', 'Podľa podielu zmeny počtu obyvateľov za celé obdobie', 'podla-prirastku-obyvatelov-od-roku-1993'],
        ['field_population_avg_age', 'Podľa priemerného veku', 'podla-najvyssieho-priemerneho-veku'],
        ['field_population_index_old_age', 'Podľa indexu starnutia', 'podla-najvyssieho-indexu-starnutia'],
        ['field_population_0_14_share', 'Podľa podielu obyvateľstva vo vekovej skupine 0 až 14 rokov', 'podla-najvacsieho-podielu-obyvatelstva-vo-vekovej-skupine-0-az-14'],
        ['field_population_15_64_share', 'Podľa podielu obyvateľstva vo vekovej skupine 15 až 64 rokov', 'podla-najvacsieho-podielu-obyvatelstva-vo-vekovej-skupine-15-az-64'],
        ['field_population_65_more_share', 'Podľa podielu obyvateľstva vo vekovej skupine 65 a viac rokov', 'podla-najvacsieho-podielu-obyvatelstva-vo-vekovej-skupine-64-a-viac'],
        // podla poctu ... preco podla narastu
        ['field_weddings_total', 'Podľa počtu svadieb', 'podla-narastu-svadieb-za-rok'],
        // podla poctu ... preco podla narastu
        ['field_divorces_total', 'Podľa počtu rozvodov', 'podla-narastu-rozvodov-za-rok'],
        ['field_dead_total', 'Podľa počtu mŕtvych', 'podla-narastu-umrti-za-rok'],
        ['field_live_birth_total', 'Podľa počtu narodených', 'podla-narastu-zivonarodenych-za-rok'],
        ['field_natural_increase_total', 'Podľa prirodzeného prírastku', 'podla-narastu-prirodzeneho-prirastku-za-rok'],
        ['field_total_increase_total', 'Podľa celkového prírastku', 'podla-narastu-celkoveho-prirastku-za-rok'],
        ['field_migration_in_permanent_tot', 'Podľa nárastu migrácie - prisťahovaných za posledný rok', 'podla-narastu-migracie-tych-co-sa-pristahovali-za-rok'],
        ['field_migration_out_permanent_to', 'Podľa nárastu migrácie - odsťahovaní za posledný rok', 'podla-narastu-migracie-tych-co-sa-odstahovali-za-rok'],
        ['field_migration_in_permanent_tot', 'Podľa migračného salda', 'podla-narastu-migracneho-salda-za-rok'],
        ['field_t_beds', 'podľa počtu lôžok za posledný rok', 'podla-poctu-lozok-za-posledny-rok'],
        ['field_t_beds_1y_share', 'podľa nárastu počtu lôžok za minulý rok', 'podla-narastu-poctu-lozok-za-posledny-rok'],
        ['field_t_objects', 'podľa počtu ubytovacích zariadení za posledný rok', 'podla-poctu-ubytovacich-zariadeni-za-posledny-rok'],
        ['field_t_objects_1y_share', 'podľa podielu ubytovacích zariadení za posledný rok', 'podla-narastu-ubytovacich-zariadeni-za-posledny-rok'],
        ['field_t_overstays', 'podľa počtu prenocovaní za posledný rok', 'podla-poctu-prenocovani-za-posledny-rok'],
        ['field_t_overstays_1y_share', 'podľa nárastu prenocovaní za posledný rok', 'podla-narastu-prenocovani-za-posledny-rok'],
        ['field_t_tourists', 'podľa počtu turistov za posledný rok', 'podla-poctu-turistov-za-posledny-rok'],
        ['field_t_tourists_1y_share', 'podľa nárastu turistov za posledný rok', 'podla-narastu-turistov-za-posledny-rok']
        // field_soil_agro_total
        // podla-celkovej-rozlohy-polnohospodarskej-pody
        // podla-celkovej-rozlohy-ornej-pody
        // field_soil_agro_arable
        // podla-celkovej-rozlohy-chmelnice"
        // field_soil_agro_hops
        // podla-celkovej-rozlohy-vinic
        // field_soil_agro_vineyard
        // podla-celkovej-rozlohy-zahrad
        // field_soil_agro_garden
        // podla-celkovej-rozlohy-ovocnych-sadov
        // field_soil_agro_orchard
        // podla-celkovej-rozlohy-travnatych-ploch
        // field_soil_agro_grassland
        // podla-celkovej-rozlohy-nepolnohospodarskej-pody
        // field_nonagro_total
        // podla-celkovej-rozlohy-lesov
        // field_nonagro_forest
        // podla-celkovej-rozlohy-vodnych-ploch
        // field_nonagro_water_area
        // podla-celkovej-rozlohy-zastavanej-pody
        // field_nonagro_built_up_area
        // podla-podielu-polnohospodarskej-pody
        // field_s_agro_sh
        // podla-podielu-ornej-pody
        // field_s_arable_sh
        // podla-podielu-chmelnice
        // field_s_hops_sh
        // podla-podielu-vinic
        // field_s_vineyard_sh
        // podla-podielu-zahrad
        // field_s_garden_sh
        // podla-podielu-ovocnych-sadov
        // field_s_orchard_sh
        // podla-podielu-travnatych-ploch
        // field_s_grassland_sh
        // podla-podielu-nepolnohospodarskej-pody
        // field_s_nonagro_sh
        // podla-podielu-lesov
        // field_s_forest_sh
        // podla-podielu-vodnych-ploch
        // field_s_water_sh
        // podla-podielu-zastavanej-pody
        // field_s_built_up_sh
    ]
    
   // array.filter( item => 
   let returnedItem
   // let path
   array.map(item => {
        if(item.includes(wantedArrayItem)) {
            // item[2] = startPath + item[2]
            returnedItem = item
            // this loop should break after a value is found - how to exit? return doesn't work...
        } 
   })
   return returnedItem
}
export default fieldsUrlsNames
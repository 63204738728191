import React from "react"
import { Link } from "gatsby"
import sortArray from "../../functions/charts/sort"
import average from "../../functions/charts/average"
import fieldsUrlsNames from "../../functions/page-names/fields-urls-names"
import Percent from '../../functions/percent';
import NumberFormat from 'react-number-format';

const Charts = (data) => {

  const { arr, field, chartPath, linkName } = data

  const translations = fieldsUrlsNames(field)

  const fieldNiceText = (translations && Array.isArray(translations) && translations[1]) ? translations[1] : 'no text'
  const sortedArray = sortArray(arr, field)
  const name = sortedArray[0].name ? 'name' : 'title' // terms have "name" value and municipalities "title" value for the name. 
  const type = sortedArray[0].field_type

  const fullPathToList = chartPath + translations[2]

  const top1name = sortedArray[0][name]
  const top1link = sortedArray[0].path.alias
  const top1value = <NumberFormat
    value={sortedArray[0][field]}
    displayType={'text'}
    thousandSeparator={' '}
  />

  const top2name = sortedArray[1][name]
  const top2link = sortedArray[1].path.alias
  const top2value = <NumberFormat
    value={sortedArray[1][field]}
    displayType={'text'}
    thousandSeparator={' '}
  />

  const top3name = sortedArray[2][name]
  const top3link = sortedArray[2].path.alias
  const top3value = <NumberFormat
    value={sortedArray[2][field]}
    displayType={'text'}
    thousandSeparator={' '}
  />


  const bottom3name = sortedArray[sortedArray.length - 3][name]
  const bottom3link = sortedArray[sortedArray.length - 3].path.alias
  const bottom3value = <NumberFormat
    value={sortedArray[sortedArray.length - 3][field]}
    displayType={'text'}
    thousandSeparator={' '}
  />

  const bottom2name = sortedArray[sortedArray.length - 2][name]
  const bottom2link = sortedArray[sortedArray.length - 2].path.alias
  const bottom2value = <NumberFormat
    value={sortedArray[sortedArray.length - 2][field]}
    displayType={'text'}
    thousandSeparator={' '}
  />

  const bottom1name = sortedArray[sortedArray.length - 1][name]
  const bottom1link = sortedArray[sortedArray.length - 3].path.alias
  const bottom1value = <NumberFormat
    value={sortedArray[sortedArray.length - 1][field]}
    displayType={'text'}
    thousandSeparator={' '}
  />

  // averageArrayValue = average(array, item)
  return <div>
    <h5>{fieldNiceText} | Najväčšie 3: </h5>
    <table>
      <tbody>
        <tr>
          <td><Link to={top1link}>{top1name}</Link></td><td>{top1value}</td>
        </tr>
        <tr>
          <td><Link to={top2link}>{top2name}</Link></td><td>{top2value}</td>
        </tr>
        <tr>
          <td><Link to={top3link}>{top3name}</Link></td><td>{top3value}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <h5>{fieldNiceText} | Najmenšie 3: </h5>
    <table>
      <tbody>
        <tr>
          <td><Link to={bottom1link}>{bottom1name}</Link></td><td>{bottom1value}</td>
        </tr>
        <tr>
          <td><Link to={bottom2link}>{bottom2name}</Link></td><td>{bottom2value}</td>
        </tr>
        <tr>
          <td><Link to={bottom3link}>{bottom3name}</Link></td><td>{bottom3value}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>Celý rebríček: <Link to={fullPathToList}>{linkName} <span style={{ textTransform: "lowercase" }}>{fieldNiceText}</span></Link></p>
    <br />
  </div>


}
export default Charts
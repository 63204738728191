import React from "react"

const sortArray = function(array, nameOfSortingField, reverse = false) {
    if (!array || !nameOfSortingField || !Array.isArray(array)) {
        return
    }
    array.sort( (a,b) => b[nameOfSortingField] - a[nameOfSortingField] )
    
    // DESC/ASC sorting 
    let lookupArray = reverse ? array.reverse() : array
    return array
    
    // to study: 
    // const idecka = obce.map(prop("field_id"))
    // filter
    // reduce
}
export default sortArray

